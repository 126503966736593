<script lang="ts">
	import HamburgerIcon from '../../lib/icons/hamburgerIcon.svelte';
	import LangSelector from './LangSelector.svelte';
	import WhiteLogo from '$lib/logo/logo-white.svg';
	import BlackLogo from '$lib/logo/logo-black.svg';

	import { darkMode } from '$store/darkMode';
	import { toggleMenu } from '$store/menu';
	import { LINK_ROOT, LOCALIZELINK } from '$utils/link';
	import { getContext } from 'svelte';
	import type { LANGUAGE } from '$lib/constants/locale';

	let lang = getContext('lang') as LANGUAGE;
</script>

<nav class="">
	<div class="mx-auto flex w-full max-w-[1120px] flex-row items-center justify-between">
		<a href={LOCALIZELINK(lang, LINK_ROOT)} aria-label="89transfers home page">
			{#if !$darkMode}
				<img src={BlackLogo} alt="light-logo-icon" class="h-[32px] w-[135px]" />
			{:else}
				<img src={WhiteLogo} alt="dark-logo-icon" class="h-[32px] w-[135px]" />
			{/if}
		</a>

		<div class="nav-right-items flex-between relative gap-4 md:gap-20">
			<div class="flex-between relative flex gap-4">
				<!-- TEMP: hidden -->
				<!-- <div class="hidden md:block">
					<ThemeSwitcher />
				</div> -->
				<LangSelector />
			</div>
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<div class="nav-links">
				<!-- Hamburger icon for mobile -->
				<button
					class="hamburger-icon mr-[20px]"
					on:click={toggleMenu}
					aria-label="Open mobile menu"
				>
					<HamburgerIcon darkMode={$darkMode} />
				</button>
			</div>
		</div>
	</div>
</nav>

<!-- <MobileMenu bind:isOpen /> -->

<style>
	nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.75rem;
	}

	nav .nav-right-items {
		display: flex;
		align-items: center;
	}

	nav a {
		margin-left: 1.5rem;
	}

	nav a:first-child {
		margin-left: 0;
		display: flex;
		align-items: center;
	}

	.hamburger-icon {
		flex-direction: column;
		justify-content: space-between;

		padding: 0;
		background-color: transparent;
		border: none;
		cursor: pointer;
	}

	@media (max-width: 768px) {
		.hamburger-icon {
			display: flex;
		}
	}
</style>
