<script lang="ts">
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import LocationSelection from '$components/landing/location-inputs/LocationSelection.svelte';
	import { isSupportedLang, type LANGUAGE } from '$lib/constants/locale';
	import isDesktop from '$store/isDesktop';
	import { isLocationSelectionIntersecting } from '$store/isLocationSelectionIntersecting';
	import { LINK_BOOKING, LOCALIZELINK } from '$utils/link';
	import { getContext } from 'svelte';
	import { cubicOut } from 'svelte/easing';
	import { fade, slide } from 'svelte/transition';
	import 'uno.css';
	import Navbar from '../../components/header/Nav.svelte';
	import GlowingButton from '$components/atoms/GlowingButton.svelte';
	let t = getContext('page-contents-landing') as Record<string, string>;

	let lang = getContext('lang') as LANGUAGE;
	$: isLanding =
		$page.url.pathname === '/' ||
		$page.url.pathname === '' ||
		($page.url.pathname.split('/').length === 2 &&
			isSupportedLang($page.url.pathname.split('/')[1]));
	$: isBooking =
		$page.url.pathname === '/booking' ||
		($page.url.pathname.split('/').length === 3 && $page.url.pathname.split('/')[2] === 'booking');

	let isBannerVisible = true;
	const handleHideBanner = () => {
		isBannerVisible = false;
		sessionStorage.setItem('isBannerVisible', 'false');
	};
</script>

<header
	class="z-1001 bg-grey-100 dark:bg-grey-800 fixed left-0 right-0 top-0 h-auto"
	class:!bg-[#F7F6F9]={isBooking}
	class:drop-shadow-custom={isLanding}
>
	<Navbar />
	{#if isLanding}
		<div
			class="w-screen-without-scroll flex-center px-5"
			style="filter: drop-shadow(0px 2px 28px rgba(142, 122, 239, 0.45));"
			in:fade|global={{ duration: 300, easing: cubicOut, delay: 900 }}
		>
			<GlowingButton
				on:click={() => {
					goto(LOCALIZELINK(lang, LINK_BOOKING));
				}}
				class="!h-13.5 mx-5 mb-5 !w-full flex-shrink-0 md:hidden"
				>{t.book_transfer_now}
			</GlowingButton>
			{#if !$isLocationSelectionIntersecting && $isDesktop}
				<div
					class="max-w-1000px mb-5 hidden w-full md:block"
					in:slide|global={{ duration: 20, easing: cubicOut, delay: 50 }}
					out:slide|global={{ duration: 20, easing: cubicOut }}
				>
					<LocationSelection />
				</div>
			{/if}
		</div>
		<!-- {#if $isLocationSelectionIntersecting && isBannerVisible}
			<Banner on:hideBanner={handleHideBanner} />
		{/if} -->
	{/if}
</header>

<style>
	.drop-shadow-custom {
		filter: drop-shadow(0px 2px 14px rgba(142, 122, 239, 0.45));
	}
</style>
