<script lang="ts">
	export let darkMode: boolean;
</script>

<!-- Hamburger svg icon -->
<svg width="24" height="24" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M9 1H19M1 7H19M5 13H19"
		stroke={darkMode ? 'white' : '#0F0F0F'}
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
